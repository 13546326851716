<template>
  <div class="container">
      <Title msg="Historial"/>
      <history />
  </div>
</template>
<script>
import history from '@/components/student/history/index.vue'
import Title from '@/views/student/constants/title.vue'

export default {
  name: 'vHistory',
  components: {
    history,
    Title
  },
  data () {
    return {
    }
  },
  created () {
  },
  computed: {
  }
}
</script>

<style scope>

</style>