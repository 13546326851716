<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-3 text-center btn_center">
        <button type="submit" class="button_actions button_click" v-on:click="showRound">Rondas</button>
      </div>
      <div class="col-3 text-center btn_center">
        <button type="submit" class="button_actions button_click" v-on:click="showVersus">Versus</button>
      </div>
    </div>
    <div v-if="loading">
      <loadingQuiz/>
    </div>
    <!---  HistoryVersus -->
    <div v-else-if="viewHistory=='versus'">     
      <div class="row justify-content-center" v-for="versus in historyVersus" :key="versus.id">
        <div class="col col-sm-12 col-md-8 col-lg-6 col-xl-6 dHistory">
          <div class="row dwin">
            <div v-if="student.id == versus[0].student.id">
              <div v-if="versus[0].score > versus[1].score && versus[0].score>1">
                <p class="pwin text-color">GANASTE</p>
              </div>
              <div v-else-if="versus[0].score == versus[1].score && versus[0].score>1">
                <p class="pwin">EMPATE</p>
              </div>
              <div v-else>
                <p class="pwin">:(</p>
              </div>
            </div>
            <div v-else>
              <div v-if="versus[1].score > versus[0].score && versus[1].score>1">
                <p class="pwin text-color">GANASTE</p>
              </div>
              <div v-else-if="versus[1].score == versus[0].score && versus[1].score>1">
                <p class="pwin">EMPATE</p>
              </div>
              <div v-else>
                <p class="pwin">:(</p>
              </div>
            </div>
          </div>
          <div class="row justify-content-md-center">
            <div class="col-">
              <div class="dprofile">
                <picture>
                  <img :src="appDomainImg + 'profile/15.' + versus[0].student.user.avatar"/>
                </picture>
              </div>
              <div class="dname">
                <p class="pname text-center text-color">{{ versus[0].student.user.name }}</p>
              </div>
              <div class="text-center dpx">
                <p class="pscorelast text-color">{{ versus[0].versus.score_creator }}EXP</p>
              </div>
            </div>
            <div class="col">
              <div class="row">
                <div class="col text-center text-color">
                  <p class="text-break">{{ versus[0].versus.block.name }}</p>
                  <p class="text-break">{{ versus[0].versus.course.name }}</p>
                  <p class="text-break">{{ versus[0].versus.team.name }}</p>
                </div>
              </div>
              <div class="row dscore text-color">
                <div class="col text-center dscore">
                  <div><p class="pscore">{{ versus[0].score }}</p></div>
                  <p>{{ (versus[0].correct_answers +  versus[0].wrong_answers +  versus[0].blank_answers)*100/versus[0].versus.amount_questions}}%</p>
                </div>
                <div class="col text-center dscore">
                  <div><p class="pscore">{{ versus[1].score }}</p></div>
                  <p>{{ (versus[1].correct_answers +  versus[1].wrong_answers +  versus[1].blank_answers)*100/versus[1].versus.amount_questions}}%</p>
                </div>
              </div>
            </div>
            <div class="col- text-color">
              <div class="dprofile">
                <picture>
                  <img :src="appDomainImg + 'profile/15.' + versus[1].student.user.avatar"/>
                </picture>
              </div>
              <div class="dname">
                <p class="pname text-center">{{ versus[1].student.user.name }}</p>
              </div>
              <div class="text-center dpx">
                <p class="pscorelast">{{versus[0].versus.score_friend}}EXP</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!---  HistoryRound -->
    <div v-else-if="viewHistory=='round'">
      <div class="row justify-content-md-center" v-for="round in historyRound" :key="round.id">
        <div class="col-12 col-sm-12 col-md-8 col-lg-6 dcourse">
            <div class="row">
              <div class="col- dprofile">
                <picture>
                    <img
                      :src="appDomainImg + 'courses/' + round.course.avatar"
                      :alt="round.course.avatar"
                    />
                  </picture>
              </div>
              <div class="col history_detall">
                <p>{{ round.block.name }}</p>
                <p>
                    {{ round.course.name }} <br>
                    {{ round.team.name }} <br>
                </p>
              </div>
              <div class="col- content_score">
                <p>{{ round.score }}</p>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import loadingQuiz from "@/views/student/constants/loading/theme.vue";
import { mapState } from "vuex";

export default {
  components: {
        loadingQuiz
    },
  data() {
    return {
      loading: false,
      historyRound: [],
      historyVersus: [],
      viewHistory:'round',
      canGetRound:false,
      canGetVersus:true
    };
  },
  created() {
    this.getHistoryRound();
  },
  methods: {
    showRound(e){
      e.preventDefault();
      console.log("btnRound");
      if (this.canGetRound) {
        this.loading = !this.loading;
        this.canGetRound = false;
        this.getHistoryRound();
      }
      this.viewHistory='round';
    },
    showVersus(e){
      e.preventDefault();
      console.log("btnVersus");
      if (this.canGetVersus) {
        this.loading = !this.loading;
        this.canGetVersus = false;
        this.getHistoryVersus();
      }
      this.viewHistory='versus';
    },
    getHistoryRound() {
      const url = this.appDomainApi1 + "history/rounds"
      this.loading = true;
      axios
        .get(url)
        .then((resp) => {
          this.historyRound = resp.data.history;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getHistoryVersus() {
      const url = this.appDomainApi1 + "history/versuses"
      this.loading = true;
      axios
        .get(url)
        .then((resp) => {
          this.historyVersus = resp.data.history;
          this.loading = false;
          console.log(resp);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    ...mapState(["student"]),
  },
};
</script>

<style>
.btn_center{
    display: flex;
    align-items: center;
    justify-content: center;
}
.button_actions {
    border-style: none;
    width: 5rem;
    height: 3rem;
    padding: .5rem;
    margin: .5rem;
    background: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.button_click:hover{
    box-shadow: 5px 5px 15px -3px #76767687;
    cursor: pointer;
}
.history_detall{
    margin-top: 12px;
    padding: 0;
    margin-left: 26px;
    font-size: 16px;
    color: #1d2b8d;
    line-height: 1.2;
}
.dcourse {
    border-radius: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 10px;
    margin-left: 0.1px;
    margin-right: 0.1px;
    margin-bottom: 10px;
    background: white;
}
.dHistory {
    border-radius: 10px;
    padding-top: 4px;
    padding-bottom: 5px;
    margin-top: 10px;
    margin-left: 0.1px;
    margin-right: 0.1px;
    margin-bottom: 10px;
    background: white;
    padding-left: 25px;
    padding-right: 25px;
}
.content_score{
    text-align: center;
    margin: 1rem;    
    font-size: 33px;
}
p {
  border: 0px;
  margin: 0px;
  padding: 0px;
  line-height: 1;
}
.dwin {
  padding: 10px;
}
.dscore {
  padding-top: 15px;
}
.pscore {
  font-size: 30px;
}
.text-color{
  color: #202020;
}
.pscorelast {
  padding: 5px;
}
.pname {
  color: white;
  line-height: 30px;
  font-size: 12px;
}
.dname {
  background: #0c2790;
  width: 100px;
  height: 30px;
  border-radius: 10rem;
}
.pwin {
  font-size: 15px;
  color:#202020;
}
.dprofile {
  width: 75px;
    height: 75px;
    background: white;
    border-radius: 50%;
    margin: 0px -10px 0px 20px;
    overflow: hidden;
    align-items: center;
    justify-items: center;
    display: grid;
    top: 5px;
}
.dprofile >picture >img{
  width: 75px;
}
.dpx {
  width: 100px;
  height: 25px;
}
</style>
